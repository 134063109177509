import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

const HomeNew = lazy(() => import("./pages/HomeNew"));
const Home = lazy(() => import("./pages/Home"));
const AboutMe = lazy(() => import("./pages/AboutMe"));
const Portfolio = lazy(() => import("./pages/Portfolio"));
const FullPortfolio = lazy(() => import("./pages/FullPortfolio"));
const Contact = lazy(() => import("./pages/Contact"));

function App() {
  return (
    <div className="App">
      <Router>
        <Suspense
          fallback={
            <div className="preloader-div">
              hi
            </div>
          }
        >
          <Switch>
            <Route exact path="/" component={HomeNew} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/about-me" component={AboutMe} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/p/:item" component={FullPortfolio} />
            <Route exact path="/contact" component={Contact} />

            <Redirect to="/" />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
